<script setup lang="ts">
import { Notification } from '@shopware-pwa/composables-next';
import { Notification as NotificationComp } from '@upa/design-system';

defineEmits<{
  (e: 'click:close', id: number): void;
}>();

const props = defineProps<{
  notification: Notification;
}>();

const iconsMap = {
  info: 'info',
  success: 'check2',
  warning: 'warning',
  danger: 'close-outline',
};

const icon = computed(() => iconsMap[props.notification.type] || 'info');
</script>
<template>
  <NotificationComp
    v-if="notification.message.length > 0"
    :id="`toast-${notification.id}`"
    :message="notification.message"
    :type="notification.type as any"
    :icon-name="icon"
    :data-testid="`notification-element-${notification.type}`"
    role="alert"
    @close-notification="$emit('click:close', notification.id)"
  />
</template>
